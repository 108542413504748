<div class="centralizator-container">
    <div class="centralizator-content">
        <div class="row">
            <div class="col-sm-12">
                <div class="section position-relative">
                    <div class="section-top">
                        <h3 class="section-title text-color-gov">Centralizator depuneri</h3>
                        <div class="section-top-actions">
                            <button type="button" class="btn btn-sm btn-info pull-right ms-1" (click)="rerenderDT(false)">
                                <i class="fa-solid fa-arrows-rotate"></i>
                            </button>
                        </div>
                    </div>
                    <div class="table-container">
                        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border hover w-100"></table>
                    </div>
                    <div class="section-spinner position-absolute" *ngIf="isLoading">
                        <div class="spinner position-absolute top-50 start-50 translate-middle">
                            <mat-spinner></mat-spinner>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>