import { AbstractControl, ValidatorFn } from '@angular/forms';

export function atLeastOneCheckedValidator(fieldNames: string[]): ValidatorFn {
    return (group: AbstractControl) => {
        if (!fieldNames || fieldNames.length === 0) {
            return null;
        }

        if (!fieldNames.some(fieldName => group.get(fieldName) ?.touched)) {
            return null;
        }

        const atLeastOneChecked = fieldNames.some(fieldName => group.get(fieldName) ?.value === true);

        if (atLeastOneChecked) {
            return null; // At least one checkbox is checked, validation passes
        } else {
            return { atLeastOneChecked: true }; // Validation fails
        }
    };
}