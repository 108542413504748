import { IdName } from ".";

export const BeneficiariDepunere : any[] = [
    {
        id: 1,
        name: 'Consiliu Județean',
        showJudet: true,
        showUat: false,
        uatLabel: ''
    },
    {
        id: 2,
        name: 'Primărie de municipiu',
        showJudet: true,
        showUat: true,
        uatLabel: 'Municipiul'
    },
    {
        id: 3,
        name: 'Primărie de oraș',
        showJudet: true,
        showUat: true,
        uatLabel: 'Orașul'
    },
    {
        id: 4,
        name: 'Primărie de comună',
        showJudet: true,
        showUat: true,
        uatLabel: 'Comuna'
    },
    {
        id: 5,
        name: 'Primăria capitalei',
        showJudet: false,
        showUat: true,
        uatLabel: 'Municipiul'
    },
    {
        id: 6,
        name: 'Primărie de sector',
        showJudet: false,
        showUat: true,
        uatLabel: 'Sectorul'
    }
]