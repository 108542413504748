<div class="content">    

    <ng-container *ngIf="!depunereResponse">
        <app-countdown-timer (datesUpdated)="onDatesUpdated($event)"></app-countdown-timer>
        <mat-card class="form-content">
            <h2 class="title-text text-color-gov text-center">Platforma de înregistrare a cererilor de finanțare</h2>
            <h2 class="welcome-text text-color-gov">Bine ați venit pe Platforma de înregistrare a Programului național
                pentru transformarea digitală a autorităților administrației publice locale.
            </h2>
    
            <ng-container *ngIf="showDepunereForm">
                <span class="info-text">
                    Parcurgeți cu atenție informațiile de pe această platformă ÎNAINTE de a
                    completa formularul de mai jos pentru înregistrarea în Platforma de Înregistrare, începând cu data de
                    XX.XX.2024 <br> <span class="check-text">Câmpurile marcate cu * sunt obligatorii</span>
                </span>
                <mat-divider></mat-divider>
                <form class="settings-container__left-address-form" [formGroup]="depunereForm">
    
                    <!-- nume reprezentant -->
                    <div class="row mb-3">
                        <mat-form-field appearance="fill">
                            <mat-label>Numele complet al reprezentantului legal</mat-label>
                            <input formControlName="nume_reprezentant" matInput type="text" required="required" />
                            <mat-hint class="d-flex justify-content-between">
                                <i class="text-red">Numele și Prenumele</i>
                            </mat-hint>
                            <mat-error
                                *ngIf="legalName && (legalName.dirty || legalName.touched) && legalName.hasError('required')">
                                Câmp obligatoriu
                            </mat-error>
                            <mat-error
                                *ngIf="legalName && (legalName.dirty || legalName.touched) && legalName.hasError('minlength')">
                                Minim 2 caractere
                            </mat-error>
                            <mat-error
                                *ngIf="legalName && (legalName.dirty || legalName.touched) && legalName.hasError('maxlength')">
                                Max 50 caractere
                            </mat-error>
                        </mat-form-field>
                    </div>
                    
                    <!-- email -->
                    <div class="row mb-3">
                        <mat-form-field appearance="fill">
                            <mat-label>Email instituțional</mat-label>
                            <input formControlName="email" matInput type="email" required="required" placeholder="email@institutie.ro" />
                            <mat-hint class="d-flex justify-content-between">
                                <i class="text-red">IMPORTANT - emailul trebuie să fie un email instituțional; link-ul către platforma de
                                    încărcare a documentelor va fi transmis către acest email</i>
                            </mat-hint>
    
                            <mat-error *ngIf="email && (email.dirty || email.touched) && email.hasError('required')">
                                Câmp obligatoriu
                            </mat-error>
                            <mat-error *ngIf="email && (email.dirty || email.touched) && email.hasError('pattern')">
                                Formatul este invalid
                            </mat-error>
                        </mat-form-field>
                    </div>
    
    
                    <!-- telefon contact-->
                    <div class="row mb-3">
                        <!-- <mat-form-field appearance="fill">
                            <mat-label>Telefon de contact</mat-label>
                            <input formControlName="telefon" matInput type="text" />
                        </mat-form-field> -->
                        <mat-form-field>
                            <mat-label>Telefon</mat-label>
                            <button disabled="true" type="button" class="country-selector" mat-button matPrefix [matMenuTriggerFor]="menu">
                                <mat-icon>expand_more</mat-icon>
                                {{ currentOption.flag }} {{ currentOption.dial_code }}
                            </button>
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="changeCountry(optionRom)">
                                    <span class="flag-wrapper">🇷🇴</span>
                                    <span class="label-wrapper">
                                        {{ optionRom.name }} {{ optionRom.dial_code }}
                                    </span>
                                </button>
                                <mat-divider class="divider"></mat-divider>
                                <button mat-menu-item *ngFor="let option of options"(click)="changeCountry(option)">
                                    <span class="flag-wrapper">{{ option.flag }}</span>
                                    <span class="label-wrapper">
                                        {{ option.name }} {{ option.dial_code }}
                                    </span>
                                </button>
                            </mat-menu>
                            <input type="text" matInput formControlName="telefon" placeholder="740999999"/>
                            <mat-hint class="d-flex justify-content-between">
                                <i class="text-red">Verificați ca numărul de telefon să fie corect introdus deoarece va fi transmis un SMS cu un cod unic în privința depunerii de documente ulterior înregistrării solicitării</i>
                            </mat-hint>  
                            <mat-error *ngIf="telefon && (telefon.dirty || telefon.touched) && telefon.hasError('required')">
                                Telefonul este obligatoriu.
                            </mat-error>
                            <mat-error *ngIf="telefon && (telefon.dirty || telefon.touched) && telefon.hasError('pattern')">
                                Formatul telefonului este invalid. Trebuie să fie un număr de telefon mobil românesc valid.
                            </mat-error>
                        </mat-form-field>
                    </div>
    
                    <!-- tip beneficiar -->
                    <div class="row mb-3">
                        <mat-form-field>
                            <mat-label>Tip beneficiar</mat-label>
                            <mat-select formControlName="tip_beneficiar" (selectionChange)="tipBeneficiarChanged()">
                                <mat-option [value]="-1">-- Alege tipul beneficiarului --</mat-option>
                                <mat-option *ngFor="let beneficiarType of beneficiarTypes" [value]="beneficiarType.id">
                                    {{ beneficiarType.name }}
                                </mat-option>
                            </mat-select>
                            <mat-hint class="d-flex justify-content-between">
                                <i class="text-red">Trebuie selectată cel puțin o opțiune</i>
                            </mat-hint>
                            <mat-error *ngIf="depunereForm.controls['tip_beneficiar']?.hasError('notDefault')">
                                Câmp obligatoriu
                            </mat-error>
                        </mat-form-field>
                    </div>
    
                    <div class="row mb-3" *ngIf="showJudet()">
                        <mat-form-field>
                            <mat-label>Județ</mat-label>
                            <mat-select formControlName="judet_id" (selectionChange)="loadUatsForJudet()">
                                <mat-option [value]="-1">-- Alege județul --</mat-option>
                                <mat-option *ngFor="let judet of judete" [value]="judet.id">
                                    {{ judet.name }}
                                </mat-option>
                            </mat-select>
                            <mat-hint class="d-flex justify-content-between">
                                <i class="text-red">Trebuie selectată cel puțin o opțiune</i>
                            </mat-hint>
                            <mat-error *ngIf="depunereForm.controls['judet']?.hasError('notDefault')">
                                Câmp obligatoriu
                            </mat-error>
                        </mat-form-field>
                    </div>
    
                    <!-- <div class="row mb-3" *ngIf="showUat()">
                        <mat-form-field>
                            <mat-label>{{beneficiarConfig.uatLabel}}</mat-label>
                            <mat-select formControlName="uat_id">
                                <mat-option [value]="-1">-- Alegeți --</mat-option>
                                <mat-option *ngFor="let uat of uats" [value]="uat.id">
                                    {{ uat.name }} - {{uat.siruta}}
                                </mat-option>
                            </mat-select>
                            <mat-hint class="d-flex justify-content-between">
                                <i class="text-red">Trebuie selectată cel puțin o opțiune</i>
                            </mat-hint>
                            <mat-error *ngIf="depunereForm.controls['uat_id']?.hasError('notDefault')">
                                Câmp obligatoriu
                            </mat-error>
                        </mat-form-field>
                    </div> -->
    
                    <div class="row mb-3" *ngIf="showUat()">
                        <mat-form-field>
                        <mat-label>{{beneficiarConfig.uatLabel}}</mat-label>
                        <input type="text" placeholder="Cautare UAT" aria-label="Cautare UAT" matInput [formControl]="uatControl" [matAutocomplete]="auto">
                    
                        <mat-autocomplete #auto="matAutocomplete">
                            <mat-option *ngFor="let uat of filteredUats | async" [value]="uat.name + ' - ' + uat.siruta">
                            {{ uat.name }} - {{ uat.siruta }}
                            </mat-option>
                        </mat-autocomplete>
                    
                        <mat-hint class="d-flex justify-content-between">
                            <i class="text-red">Trebuie selectată cel puțin o opțiune</i>
                        </mat-hint>
                        <mat-error *ngIf="depunereForm.controls['uat_id']?.hasError('notDefault')">
                            Câmp obligatoriu
                        </mat-error>
                        </mat-form-field>
                    </div>
                            
                    <!-- buttons -->
                    <div class="mt-5 d-flex justify-content-center">
                        <button type="button" class="black-btn" (click)="sendDepunere()" [disabled]="!depunereForm.valid || loading" [ngClass]="{'disabled-btn': !depunereForm.valid || loading}">
                            Înregistrare solicitare
                        </button>
                    </div>
                </form>
            </ng-container>
        </mat-card>

    </ng-container>

    <ng-container *ngIf="depunereResponse">
        <mat-card class="form-content">
            <div class="row gap-4 text-center">
                <div class="success d-flex justify-content-center align-items-center gap-3">
                    <img src="assets/icons/success-check.svg" alt="Succes" />
                    <span class="success-text">Succes!</span>
                </div>
                <span class="message-text">Sunteți nr. <span class="message-text-color">#{{depunereResponse.reg_number}}</span></span>
                <div class="verify-text row text-start">
                    <span>* Salvați nr. de ordine de mai sus deoarece îl veți folosi pentru a încărca documentele.</span>
                    <span>* Un link unic pentru încărcarea documentelor a fost trimis către adresa de email menționată în câmpurile de înscriere.</span>
                    <span>* După apăsarea butonului de " ÎNCĂRCARE DOCUMENTE ", veți primi un SMS ce va conține codul de 5 cifre necesar depunerii documentelor.</span>
                </div>
                <div>
                    <button type="button" class="result-btn" (click)="navigateToLoadFiles()">
                        ÎNCĂRCARE DOCUMENTE
                    </button>
                </div>
            </div>
        </mat-card>
        <!-- <div class="row justify-content-center mt-5">
            <p class="text-center">* Salvați nr. de înregistrare de mai sus deoarece îl veți folosi pentru a încărca documentele.</p>
            <p class="text-center">* Un link unic pentru încărcarea documentelor a fost trimis către adresa de email menționată în câmpurile de înscriere.</p>
            <p class="text-center">* După apăsarea butonului de 'Depunere documente', veți primi un SMS ce va conține codul de 5 cifre necesar depunerii documentelor.</p>
        </div> -->
    </ng-container>

    <div class="section-spinner position-absolute" *ngIf="loading">
        <div class="spinner position-absolute top-50 start-50 translate-middle">
            <mat-spinner></mat-spinner>
        </div>
    </div>
</div>