import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material.module';
import { HomepageComponent } from './homepage.component';
import { FaqComponent } from './faq/faq.component';
import { ContactComponent } from './contact/contact.component';
import { AcasaComponent } from './acasa/acasa.component';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './footer/footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogComponent } from './dialog/dialog.component';
import { AccessibilityToolbarModule } from '../components/accessibility-toolbar/accessibility-toolbar.module';


@NgModule({
    declarations: [
        HomepageComponent, 
        AcasaComponent, 
        FaqComponent, 
        ContactComponent, 
        FooterComponent, 
        DialogComponent, 
    ],
    imports: [
        CommonModule,
        MaterialModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        AccessibilityToolbarModule
    ],
})
export class HomepageModule { }
