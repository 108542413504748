import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from 'src/app/material.module';
import { CheckCodeComponent } from './check-code/check-code.component';
import { DepunereComponent } from './depunere.component';
import { SearchDepunereComponent } from './search-depunere/search-depunere.component';
import { IncarcareDocumenteComponent } from './incarcare-documente/incarcare-documente.component';
import { DepunereRoutingModule } from './depunere-routing.module';
import { CentralizatorComponent } from './centralizator/centralizator.component';
import { CountdownTimerComponent } from '../countdown-timer/countdown-timer.component';

@NgModule({
	declarations: [
		DepunereComponent,
		SearchDepunereComponent,
		IncarcareDocumenteComponent,
		CheckCodeComponent,
		CentralizatorComponent,
		CountdownTimerComponent
	],
	imports: [
		CommonModule,
		DepunereRoutingModule,
		FormsModule,
		ReactiveFormsModule,
		MaterialModule		
	]
})
export class DepunereModule { }
