<mat-card>
    <div class="countdown-timer d-flex flex-lg-row flex-column justify-content-center align-items-center" *ngIf="countdownText !== 'Perioada de depunere s-a încheiat.'">
        <p class="m-0">{{ countdownText }}</p>
        <div>
            <div class="timer">
                <div class="timer-unit">
                    <span class="time">{{ days }}</span>
                </div>
                <div class="timer-unit">
                    <span class="time">{{ hours }}</span>
                </div>
                <div class="timer-unit">
                    <span class="time">{{ minutes }}</span>
                </div>
                <div class="timer-unit">
                    <span class="time">{{ seconds }}</span>
                </div>
            </div>
            <div class="labels">
                <span class="label">Zile</span>
                <span class="label">Ore</span>
                <span class="label">Minute</span>
                <span class="label">Secunde</span>
            </div>
        </div>
    </div>

    <p *ngIf="countdownText === 'Perioada de depunere s-a încheiat.'">{{ countdownText }}</p>

</mat-card>