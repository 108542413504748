import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SuportOnlineModule } from './components/suport-online/suport-online.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { HttpLoadingInterceptor } from './interceptors/http-loading.interceptor';

import { HomepageModule } from './homepage/homepage.module';
import { MaterialModule } from './material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DepunereModule } from './components/depunere/depunere.module';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,  
        DepunereModule,      
        SuportOnlineModule,
        ToastrModule.forRoot(),
        HomepageModule,
        MaterialModule,
        ReactiveFormsModule,
        FormsModule
    ],
    providers: [       
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpLoadingInterceptor,
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
