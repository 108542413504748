<div class="search-container">

    <div class="search-content">
        <h2 class="mb-4">Căutare proiect</h2>
           
        <div class="row justify-content-center">
            <form [formGroup]="searchForm" *ngIf="!depunere">
                <div class="row mb-3">
                    <mat-form-field appearance="fill" class="w-100">
                        <mat-label>Nr. Solicitare</mat-label>
                        <input formControlName="reg_number" matInput type="number" required="required" />
                        <mat-error *ngIf="regNumber && (regNumber.dirty || regNumber.touched) && regNumber.hasError('required')">
                            Câmp obligatoriu
                        </mat-error>
                    </mat-form-field>
                </div>
            
                <mat-divider/>
            
                <div class="row mb-3">
                    <mat-form-field appearance="fill"  class="w-100">
                        <mat-label>Siruta UAT</mat-label>
                        <input formControlName="cif_uat" matInput type="text" required="required" />
                        <mat-error *ngIf="cifUat && (cifUat.dirty || cifUat.touched) && cifUat.hasError('required')">
                            Câmp obligatoriu
                        </mat-error>
                    </mat-form-field>
                </div>    
            
                <!-- buttons -->
                <div class="mt-5 d-flex justify-content-center">
                    <button type="button" class="black-btn" (click)="searchDepunere()" [disabled]="!searchForm.valid || loading" [ngClass]="{'disabled-btn': !searchForm.valid || loading}">
                        Pasul următor
                    </button>
                </div>
            </form>
    
        </div>
    
        <div class="section-spinner position-absolute" *ngIf="loading">
            <div class="spinner position-absolute top-50 start-50 translate-middle">
                <mat-spinner></mat-spinner>
            </div>
        </div>
    </div>
    

</div>




