import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs';
import { NotificationService } from 'src/app/services/notification.service';
import { ToastService } from 'src/app/services/toast.service';
import { environment } from 'src/environments/environment';
import { DepunereService } from '../depunere.service';
import { ActivatedRoute, Router } from '@angular/router';
import { helper } from 'src/environments/helper';
import { InitIncarcareDocumente } from 'src/app/models';

@Component({
    selector: 'app-check-code',
    templateUrl: './check-code.component.html',
    styleUrls: ['./check-code.component.scss']
})
export class CheckCodeComponent {
    helper = helper;
    loading = false;
    form: FormGroup;
    cerereToken: string = '';
    allowResendSMS: boolean = true

    // general error
    errorTitle: string = environment.config.customNotifications.headers.error
    errorIcon: string = environment.config.customNotifications.icons.error
    errorType: string = 'error'

    // general success alert
    successTitle: string = environment.config.customNotifications.headers.success
    successIcon: string = environment.config.customNotifications.icons.success
    successType: string = 'success'

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private depunereService: DepunereService,
        private toastService: ToastService,
        private notificationService: NotificationService
    ) {
        this.form = new FormGroup({
            code: new FormControl('', Validators.compose([
                Validators.required,
                Validators.minLength(5),
                Validators.maxLength(5)
            ]))
        });
    }

    ngOnInit(): void {
        this.route.params.subscribe(params => {
            this.cerereToken = params['cerere_token'];
            this.depunereService.updateState({token: this.cerereToken});
          });    
    }

    get codeControl() {
        return this.form.get('code');
    }

    

    resendCode() {
        if (this.allowResendSMS) {            
            this.loading = true;
            
            this.depunereService.resendCode({ token : this.cerereToken })
                .pipe(
                    finalize(() => this.loading = false)
                )
                .subscribe({
                    next: (res: any) => {
                        let response = (typeof res.status_code !== 'undefined' ? res : res.error);
                        if (typeof response.status_code !== 'undefined') {
                            if (response.status_code == 200 && typeof response.data !== 'undefined') {
                                // success
                                this.allowResendSMS = false;
                                this.toastService.openToast({ title: this.successTitle, message: 'Codul a fost retrimis prin sms', type: this.successType })
                                
                                //this.codeControl?.patchValue(response.data.token);

                                setTimeout(() => {
                                    this.allowResendSMS = true
                                }, 60000)
                            }
                        } else {
                            // general error
                            let errorMessage = environment.config.customNotifications.generalMessages.error;
                            this.loading = false;
                            this.toastService.openToast({ title: this.errorTitle, message: errorMessage, type: this.errorType });
                        }
                    },
                    error: (err: any) => {
                        this.notificationService.handleHttpError(err.error);
                        this.loading = false;
                    }
                });
        } else {
            this.toastService.openToast({ title: this.errorTitle, message: 'Puteti trimite urmatorul cod in cca 1 minut', type: this.errorType });
        }
    }

    async confirmCode() {
        if (!this.form.valid) {
            await this.notificationService.warningSwal(this.errorTitle, 'Va rugam sa completati codul primit prin sms', this.errorIcon)
            return;
        }
        
        this.loading = true;

        const currentState = this.depunereService.getCurrentInitIncarcareDocumente();
        this.depunereService.confirmCode({ code: this.form.value.code, token: currentState?.token })
            .subscribe({
                next: (res: any) => {
                    this.loading = false;
                    let response = (typeof res.status_code !== 'undefined' ? res : res.error)
                    if (typeof response.status_code !== 'undefined') {
                        if (response.status_code == 200 && typeof response.data !== 'undefined') {
                            // success
                            // TODO : write response data to service in order to validate from depunere.
                            console.log('CONFIRM CODE: ', response);
                            
                            this.depunereService.updateState({mainToken: response.data.mainToken});                           

                            this.validateTokens(response.data.mainToken, currentState?.token);
                        }
                    } else {
                        // general error
                        let errorMessage = environment.config.customNotifications.generalMessages.error;
                        this.loading = false;

                        this.toastService.openToast({ title: this.errorTitle, message: errorMessage, type: this.errorType })
                    }
                },
                error: (err: any) => {
                    this.notificationService.handleHttpError(err.error);
                    this.loading = false;
                }
            });
    }

    validateTokens(mainToken: string, token: string) {
        this.loading = true;
            
            return this.depunereService.validateToken({ token : token, mainToken: mainToken })
                .pipe(
                    finalize(() => this.loading = false)
                )
                .subscribe({
                    next: (res: any) => {
                        this.router.navigate(['depunere','documente']);
                    },
                    error: (err: any) => {
                        this.notificationService.handleHttpError(err.error).then( () => this.router.navigate(['depunere/search']));
                    }
                });
    }
}
