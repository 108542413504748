import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';
import { finalize } from 'rxjs';
import { helper } from 'src/environments/helper';


export enum LINKS {
    solicitariDgitl = 'public/solicitari/dgitl',
    solicitariUrbanism = 'public/solicitari/urbanism',
}

@Component({
    selector: 'app-acasa',
    templateUrl: './acasa.component.html',
    styleUrls: ['./acasa.component.scss']
})
export class AcasaComponent {
    platformName = environment.config.platformName;

    public coreModuleUrl = environment.interop.depunereCore.basePath + 'documents';
    
    isLoading = false;
    helper = helper;

    constructor(private router: Router) { }

    ngOnInit(): void {
    }

    navigateTo(route: string) {
        this.router.navigate([route]);
    }

    navigateToNewTab(route: string) {
        window.open(route, '_blank');
    }
}
