import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DepunereService } from 'src/app/components/depunere/depunere.service';
import { InitIncarcareDocumente } from 'src/app/models';
import { NotificationService } from 'src/app/services/notification.service';
import { helper } from 'src/environments/helper';

@Component({
  selector: 'app-search-depunere',
  templateUrl: './search-depunere.component.html',
  styleUrls: ['./search-depunere.component.scss']
})
export class SearchDepunereComponent implements OnInit  {
    helper = helper;
    loading = false;
    searchForm: FormGroup;
    depunere : any = null;

    constructor(        
        private router: Router,
        private fb: FormBuilder, 
        private depunereService: DepunereService, 
        private notificationService: NotificationService) {
        
            this.searchForm = fb.group({
                reg_number: fb.control('', [Validators.required]),            
                cif_uat: fb.control('', [Validators.required])
        });
    }
    
    ngOnInit(): void {
    }

    get regNumber() {
        return this.searchForm.get('reg_number');
    }

    get cifUat() {
        return this.searchForm.get('cif_uat');
    }

    searchDepunere() {
        this.loading = true;

        const dataToSend = {
            ...this.searchForm?.getRawValue()
        };

        return this.depunereService.searchDepunere(dataToSend)
        .subscribe({
            next: (res: any) => {
                if(res.data) {
                    this.depunere = res.data;
                    this.depunereService.updateState({reg_number: this.regNumber?.value, token: res.data.token});
                    this.router.navigate(['depunere','confirmare-cod', this.depunere?.token]);
                }                
            },
            error: (err: any) => {
                this.loading = false;
                this.notificationService.handleHttpError(err.error);
            },
        });  
    }
}
