import { UploadDocument } from '../../../models';

export const DocumentsToUpload: UploadDocument[] = [
    {
        key: 'anexa1',
        uploading: false,
        name: '<strong>Anexa 1</strong> – Declarație privind capacitatea financiară a solicitantului',
        isRequired: true
    },
    {
        key: 'anexa2',
        uploading: false,
        name: '<strong>Anexa 2</strong> – Cererea de Finanțare',
        isRequired: true
    },
    {
        key: 'anexa3',
        uploading: false,
        name: '<strong>Anexa 3</strong> – Declarație cu privire la formarea profesională a personalului care va utiliza echipamentele/sistemele informatice ce urmează a fi achiziționate',
        isRequired: true
    },
    {
        key: 'anexa4',
        uploading: false,
        name: '<strong>Anexa 4</strong> – Declarație cu privire la existența sau nu a unei pagini de internet securizată, proprie, funcțională',
        isRequired: true
    },
    {
        key: 'anexa5',
        uploading: false,
        name: '<strong>Anexa 5</strong> – Declarație cu privire la asigurarea sustenabilității echipamentelor/sistemelor informatice ce urmează a fi achiziționate',
        isRequired: true
    },
    {
        key: 'anexa6',
        uploading: false,
        name: '<strong>Anexa 6</strong> – Declarație privind  îndeplinirea condiției prevăzute la art. 1 alin. (10) din OUG nr. 56/2024',
        isRequired: true
    },
    {
        key: 'anexa7',
        uploading: false,
        name: '<strong>Anexa 7</strong> – Declarație privind destinația echipamentelor/sistemelor informatice ce urmează a fi achiziționate',
        isRequired: true
    },
    {
        key: 'anexa8',
        uploading: false,
        name: '<strong>Anexa 8</strong> – Declarație privind înrolarea autorității executive în platforma ghișeul.ro',
        isRequired: true
    },
    // {
    //     key: 'anexa9',
    //     uploading: false,
    //     name: '<strong>Anexa 9</strong> – Contractul de Finanțare',
    //     isRequired: true
    // },
];