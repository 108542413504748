import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, catchError, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { InitIncarcareDocumente } from 'src/app/models';

@Injectable({
    providedIn: 'root'
})
export class DepunereService {
    private baseUrl = environment.interop.depunereCore.basePath;
    private sendDepunereUrl = this.baseUrl + environment.interop.depunereCore.api.sendDepunere;
    private searchDepunereUrl = this.baseUrl + environment.interop.depunereCore.api.searchDepunere;
    private sendDocumenteUrl = this.baseUrl + environment.interop.depunereCore.api.sendDocumente;
    private resendCodeUrl = this.baseUrl + environment.interop.depunereCore.api.resendCode;
    private confirmCodeUrl = this.baseUrl + environment.interop.depunereCore.api.confirmCode;
    private validateTokenUrl = this.baseUrl + environment.interop.depunereCore.api.validateToken;
    private getCentalizatorUrl = this.baseUrl + environment.interop.depunereCore.api.centralizator;
    private getSubmissionTimeUrl = this.baseUrl + environment.interop.depunereCore.api.submissionTime;

    private incarcareDocumenteDataSubject = new BehaviorSubject<InitIncarcareDocumente | null>(null);

    constructor(private http: HttpClient) { }

    sendDepunere(data: any) {
        return this.http.post<{ data: any }>(this.sendDepunereUrl, data);
    }

    searchDepunere(data: any) {
        return this.http.post<{ data: any }>(this.searchDepunereUrl, data);
    }

    sendDocumente(data: any) {
        return this.http.post<{ data: any }>(this.sendDocumenteUrl, data);
    }

    getCentralizator(data: any) {
        return this.http.post<{ data: any }>(this.getCentalizatorUrl, data);
    }

    resendCode(data: any) {
        return this.http.post<{ data: any }>(this.resendCodeUrl, data);
    }

    confirmCode(data: any) {
        return this.http.post<{ data: any }>(this.confirmCodeUrl, data);
    }

    validateToken(data: any) {
        return this.http.post<{ data: any }>(this.validateTokenUrl, data);
    }

    // depunere STATE
    setInitIncarcareDocumente(data: InitIncarcareDocumente | null) {
        this.incarcareDocumenteDataSubject.next(data);
    }

    getInitIncarcareDocumente() {
        return this.incarcareDocumenteDataSubject.asObservable();
    }

    getCurrentInitIncarcareDocumente() {
        return this.incarcareDocumenteDataSubject.value;
    }

    updateState(updatedStateProps: any) {
        const currentData = this.getCurrentInitIncarcareDocumente();
        const updatedData = { ...currentData, ...updatedStateProps };
        this.setInitIncarcareDocumente(updatedData as InitIncarcareDocumente);

        console.log('updateState : ', this.getCurrentInitIncarcareDocumente()); // For debugging purposes
    }

    clearState() {
        this.setInitIncarcareDocumente(null);
    }

    getSubmissionTime() {
        return this.http.get(this.getSubmissionTimeUrl);
    }
}