export const environment = {
    production: false,
    version: '1.0.0',
    config: {
        romanianLanguageDataTable: {
            sEmptyTable: 'Niciun rezultat găsit',
            sInfo: 'Afișate de la _START_ până la _END_ din _TOTAL_ înregistrări',
            sInfoEmpty: 'Afișate de la 0 până la 0 din 0 înregistrări',
            sInfoFiltered: '(filtrat din _MAX_ înregistrări)',
            sInfoPostFix: '',
            sInfoThousands: ',',
            sLengthMenu: 'Afișează _MENU_ înregistrări pe pagină',
            sLoadingRecords: 'Încărcare...',
            sProcessing: 'Procesare...',
            sSearch: 'Caută:',
            sZeroRecords: 'Niciun rezultat găsit',
            oPaginate: {
                sFirst: 'Prima',
                sLast: 'Ultima',
                sNext: 'Următoarea',
                sPrevious: 'Anterioară',
            },
        },
        copyrightText: '© Copyright 2024 - MCID.',
        appName: 'MINISTERUL CERCETĂRII, INOVĂRII ŞI DIGITALIZĂRII',
        platformName: 'Platforma de înregistrare în programul național pentru transformarea digitală a autorităților publice locale',
        defaultLNG: 'ro',
        emailRegex:
            '^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$',
        codeSMSRegex: /^[0-9]+$/,
        phoneRegex: '^(\\+4)?0?(7[0-9]{8})$',
        cnpRegex: '[0-9]{13,13}',
        validatorsAccrossApp: {
            minPassword: 8,
            maxPassword: 50,
            emailMaxLength: 50,
            minStringLength: 2,
            maxStringLength: 60,
            minSmsCodeLength: 1,
            maxSmsCodeLength: 1,
        },
        customNotifications: {
            icons: {
                success: 'success',
                error: 'error',
                info: 'info',
                warning: 'warning',
            },
            headers: {
                success: 'Felicitări!',
                error: 'Atenție',
                successForm: 'Felicitări!',
            },
            generalMessages: {
                error:
                    'Ne pare rău, dar am întâmpinat o problemă. Vă rugăm să reîncercați. Dacă problema persistă, vă rugăm să ne contactați prin butonul de suport IT.',
                fileErrorMsg:
                    'Ne pare rău, dar fișierul selectat nu a putut fi încărcat. Vă rugăm să reîncercați!',
            },
        },
    },
    interop: {
        depunereCore: {
            basePath: 'https://depunere-core.mcid.staging.lcinnova.ro/',
            api: {
                sendContact: 'support-online',
                sendDepunere: 'store',
                searchDepunere: 'search',
                sendDocumente: 'upload-documents',
                sendSupportOnline: 'suport-online',
                findDepunere: 'find/', // + {CERERE_TOKEN}
                centralizator: 'list',
                uploadFile: 'upload',
                resendCode: 'send-sms',
                confirmCode: 'confirm-code',
                validateToken: 'validate-token',
                judete: 'judete/list/public',
                uats: 'uat/list',// + {judet}
                announces: 'announces/list/', // + {slug}
                faq: 'faq/list/', // + {slug},
                content: 'content/list/', // + {slug},
                submissionTime: 'submission-time',
            }
        }
    }
}