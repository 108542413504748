import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, of, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IdName, UatResponse, UploadDocument } from '../models';
import { ToastService } from './toast.service';
import { NotificationService } from './notification.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { trueValueValidator } from '../shared/validators';


export interface Uat {
    id: number;
    name: string;
    judetId: number;
}

@Injectable({
    providedIn: 'root'
})
export class CommonService {
    private baseUrl = environment.interop.depunereCore.basePath;
    private getJudeteUrl = this.baseUrl + environment.interop.depunereCore.api.judete;
    private getUatsUrl = this.baseUrl + environment.interop.depunereCore.api.uats;
    private fileUploadUrl = this.baseUrl + environment.interop.depunereCore.api.uploadFile;
    private sendSupportUrl = this.baseUrl + environment.interop.depunereCore.api.sendSupportOnline;
    private faqUrl = this.baseUrl + environment.interop.depunereCore.api.faq;
    private contentUrl = this.baseUrl + environment.interop.depunereCore.api.content;
    private sendContactUrl = this.baseUrl + environment.interop.depunereCore.api.sendContact;

    errorTitle: string = environment.config.customNotifications.headers.error;
    errorIcon: string = environment.config.customNotifications.icons.error;
    errorType: string = environment.config.customNotifications.icons.error;
    // general success alert
    successTitle: string = environment.config.customNotifications.headers.successForm;
    successIcon: string = environment.config.customNotifications.icons.success;
    successType: string = environment.config.customNotifications.icons.success;

    // TMP STATIC DATA
    private judete: IdName[] = [
        { id: 1, name: 'Arad' },
        { id: 2, name: 'Bucuresti' },
        { id: 3, name: 'Bacau' },
        { id: 4, name: 'Timisoara' }
    ];

    private uats: Uat[] = [
        { id: 1, name: 'UAT AR 1', judetId: 1 },
        { id: 2, name: 'UAT AR 2', judetId: 1 },
        { id: 3, name: 'UAT B 1', judetId: 2 },
        { id: 4, name: 'UAT B 2', judetId: 2 },
        { id: 5, name: 'UAT BC 1', judetId: 3 },
        { id: 6, name: 'UAT BC 2', judetId: 3 },
        { id: 7, name: 'UAT TM 1', judetId: 4 },
        { id: 8, name: 'UAT TM 2', judetId: 4 }
    ];

    constructor(
        private http: HttpClient,
        private toastService: ToastService,
        private notificationService: NotificationService,
        private fb: FormBuilder) { }

    getJudete() {
        //return of(this.judete);

        return this.http
            .get<{ errors: boolean; data: any[]; status_code: number }>(
                this.getJudeteUrl
            )
            .pipe(
                map((response: any) =>
                    response.data.map((item: IdName) => ({
                        id: item.id,
                        name: item.name,
                    }))
                )
            );
    }

    getUats(qParams: string) {
        //return of(this.uats.filter(uat => uat.judetId === judetId));

        return this.http
            .get(this.getUatsUrl + qParams)
            .pipe(
                map((response: any) =>
                    response.data.map((item: UatResponse) => ({
                        id: item.id,
                        name: item.name,
                        siruta: item.siruta,
                    }))
                )
            );
    }

    getFAQ(slug: string) {
        return this.http.get(`${this.faqUrl}${slug}`);
    }

    sendSupport(formData: FormData) {
        return this.http.post<{ data: any }>(this.sendSupportUrl, formData);
    }

    sendContact(data: any) {
        return this.http.post<{ data: any }>(this.sendContactUrl, data);
    }

    getContent(slug: string) {
        return this.http.get(`${this.contentUrl}${slug}`);
    }

    //UPLOADING DOCUMENTS
    upload(
        file: File,
        key: string,
        documents = new Array<UploadDocument>(),
        formUpload: FormGroup<any>
    ) {
        this.setUploading(documents, key, true);
        const formData = new FormData();
        formData.append('file', file);

        const uploadApi = this.fileUpload(formData);

        const subscription = uploadApi.subscribe({
            next: (response: any) => {
                this.toastService.openToast({title: this.successTitle, message: 'Fișierul dvs a fost adăugat cu succes.', type: this.successIcon});

                const fileGroup = this.fb.group({
                    filename: [response.data.filename],
                    original_name: [response.data.original_name],
                    ext: [response.data.ext],
                });

                const uploadArray = this.getFormArray(formUpload, key);
                uploadArray.push(fileGroup);
            },
            error: (error: any) => {
                this.setUploading(documents, key, false);
                console.error(`Error uploading file ${file.name}:`, error);
                let errorMessage =
                    environment.config.customNotifications.generalMessages.error;
                this.notificationService.warningSwal(
                    this.errorTitle,
                    errorMessage,
                    this.errorIcon
                );
            },
            complete: () => {
                subscription.unsubscribe();
                this.setUploading(documents, key, false);
            },
        });
    }

    fileUpload(formData: FormData) {
        return this.http.post<{ data: any }>(this.fileUploadUrl, formData);
    }

    mapToFormUpload(uploadDocuments: UploadDocument[]) {
        const formUpload: FormGroup = this.fb.group({
            falsInDeclaratii: ['', [Validators.required, trueValueValidator()]],
            declaratieElectronica: ['', [Validators.required, trueValueValidator()]],
            termeniConditii: ['', [Validators.required, trueValueValidator()]]
        });
        
        uploadDocuments.forEach(uploadDoc => {
            const formArray = uploadDoc.isRequired ? this.fb.array([], Validators.required) : this.fb.array([]);
            formUpload.addControl(uploadDoc.key, formArray);
        });
        return formUpload;
    }

    addUploadingControls(form: FormGroup, uploadDocuments: UploadDocument[]) {
        uploadDocuments.forEach(uploadDoc => {
            const formArray = uploadDoc.isRequired ? this.fb.array([], Validators.required) : this.fb.array([]);
            form.addControl(uploadDoc.key, formArray);
        });
    }

    getUploading(documents: UploadDocument[], desiredKey: string) {
        const desiredDocument = this.getDocumentByKey(documents, desiredKey);
        if (desiredDocument) {
            return desiredDocument.uploading;
        } else {
            console.log('Document not found.');
        }
        return false;
    }

    setUploading(
        documents: UploadDocument[],
        desiredKey: string,
        value: boolean
    ) {
        const desiredDocument = this.getDocumentByKey(documents, desiredKey);
        if (desiredDocument) {
            desiredDocument.uploading = value;
        } else {
            console.log('Document not found.');
        }
    }

    private getDocumentByKey(documents: UploadDocument[], desiredKey: string) {
        return documents.find((document) => document.key === desiredKey);
    }

    getFormArray(form: FormGroup<any>, formArrName: string) {        
        return form.controls[formArrName] as FormArray;
    }
}