<div class="acasa-container">
    <div class="acasa-content">    
        
        <!-- <div class="col-md order-md-2 right-col sticky-card">  
            <mat-card class="p-5 sticky-card">
                <h2 class="heading-style-h4">Accesati aici formularul de inregistrare!</h2>
                <p class="paragraph-2">
                    <strong>Inregistrarea</strong> prealabilă pe platforma curentă, <strong>începând cu data de </strong>
                    <strong>XX.2024, </strong>
                    unde UAT-urile vor completa informații referitoare la
                    <strong> denumirea UAT, județ, numele reprezentantului legal, email și număr de telefon. </strong>
                    Ordinea transmiterii acestor informații va fi generată pe criteriul „primul venit, primul servit”.
                    <strong> Înregistrarea în platforma curentă va genera un email care va conține numărul de ordine și linkul către platforma de încărcare a documentelor (descrisă în pasul 2).</strong>
                </p>
                <a (click)="navigateTo('depunere')" class="black-btn">Formular de inregistrare</a>
            </mat-card>            
        </div> -->
        
        <div class="col-md order-md-1 left-col">
            <h1 class="heading-style-h2 text-color-gov text-center">Platforma de înregistrare în programul național pentru transformarea digitală a autorităților publice locale</h1>
            <!-- <mat-divider/> -->
            <h2 class="heading-style-h4 text-color-gov mt-5">Descrierea și obiectivele Programului</h2>            
            <p>
                <strong>Programul național pentru transformarea digitală a autorităților administrației publice locale are ca scop principal îmbunătățirea interacțiunii dintre cetățeni, mediul de afaceri și stat prin digitalizarea serviciilor publice.</strong> În contextul unui scor scăzut al României în furnizarea de servicii digitale comparativ cu media UE, programul oferă sprijin direct autorităților locale pentru creșterea eficienței și transparenței administrative.<br><br>‍<strong>Programul național pentru transformarea digitală a autorităților publice locale</strong> se adresează autorităților executive la nivelul unităților administrativ – teritoriale (UAT) și se desfășoară în baza <strong>Ordonanței de urgență a Guvernului nr. 56/2024 </strong>privind aprobarea Programului național pentru transformarea digitală a autorităților publice locale și a <strong>normelor metodologice</strong> aferente.<br><br>Programul urmărește dezvoltarea unor module informatice noi care să deservească activitatea autorităților executive la nivelul unităților administrativ – teritoriale prin demararea unui proces de digitalizare, prin achiziționarea și instalarea echipamentelor IT necesare (echipamente IT, echipamente pentru comunicații, și echipamente conexe, inclusiv licențe), migrarea de date către noi sisteme și infrastructuri, dezvoltare de aplicații informatice aferente, precum și instruirea personalului tehnic care va utiliza echipamentele/sistemele informatice ce urmează a fi achiziționate la nivel local. Consultați secțiunea <strong>Definiții</strong> de la final pentru mai multe detalii.
            </p>
            <h2 class="heading-style-h4 text-color-gov mt-5">Finanțarea acordată</h2>            
            <div class="w-richtext">
                <p>Finanțarea se va acorda diferențiat, în funcție de tipul Beneficiarului, respectiv:</p>
                <ul role="list">
                    <li>finanțare în cuantum de maxim 250.000 lei pentru consiliile județene și primăria capitalei</li>
                    <li>finanțare în cuantum de maxim 175.000 lei pentru primăriile de municipiu și primăriile de sector din București</li>
                    <li>finanțare în cuantum de maxim 125.000 lei pentru primăriile de orașe</li><li>finanțare în cuantum de maxim 75.000 lei pentru primăriile de comune</li>
                </ul>
                <p>
                    Cererile de finanțare se vor finanța în procent de 100%, în limita cuantumurilor anterior menționate. În situația în care valoarea produselor și serviciilor din cererea de finanțare depășește limitele anterior stabilite, diferența va fi suportată de către Beneficiar, din fonduri proprii iar în cuprinsul cererii de finanțare vor fi detaliate cheltuielile eligibile care se vor acoperi din finanțarea prin Program.
                </p>
            </div>
            <h2 class="heading-style-h4 text-color-gov mt-5">Procesul de aplicare</h2>
            <div class="w-richtext">
                <p><strong>Cererile de finanțare depuse în cadrul Programului vor parcurge un proces competițional unde principiul acordării finanțării este de tipul „primul venit, primul servit”.</strong></p>
                <p><strong>Procesul de aplicare conține doi pași:</strong></p>
                <ol role="list">
                    <li><strong>înregistrarea</strong> prealabilă pe platforma curentă, <strong>începând cu data de </strong> <strong>XX.2024, </strong>unde UAT-urile vor completa informații referitoare la<strong> denumirea UAT, județ, numele reprezentantului legal, email și număr de telefon. </strong>Ordinea transmiterii acestor informații va fi generată pe criteriul „primul venit, primul servit”.<strong> Înregistrarea în platforma curentă va genera un email care va conține numărul de ordine și linkul către platforma de încărcare a documentelor (descrisă în pasul 2).</strong></li>
                    <li><strong>încărcarea documentelor</strong> (Cererea de finanțare și Anexele) în platforma MCID, prin linkul primit prin e-mail în urma înregistrării, <strong>până la data de XX.XX.2024</strong>.</li>
                </ol>
                <p>
                    <strong>Cererile de finanțare vor fi evaluate în ordinea înregistrării în platforma curentă și în baza documentelor încărcate în platforma primită în e-mailul de confirmare. Evaluarea nu este posibilă fără parcurgerea ambilor pași descriși mai sus. </strong>
                </p>
            </div>
            <h2 class="heading-style-h4 text-color-gov mt-5">Criteriile de eligibilitate</h2>
            <div class="w-richtext">
                <p>
                    <strong>Evaluarea cererilor de finanțare seface în baza următoarelor criterii de eligibilitate și a încărcării lor înplatforma transmisă în e-mailul de confirmare primit automat în urmaînregistrării: 
                    <br>‍</strong>
                </p>
                <p>
                    <strong>1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Declarația privind capacitatea financiară a solicitantului</strong>
                </p>
                <p>
                    În situația în care suma solicitată prin cererea de finanțare depășește suma cepoate fi alocată, 
                    <strong>diferența până la valoarea totală a proiectului va fisuportată din fondurile proprii ale beneficiarului.</strong> 
                    În această situație cererea de finanțare trebuie să conțină 
                    <strong>o declarație pe proprie răspundereprivind capacitatea financiară a solicitantului</strong> 
                    cu privire la existențadisponibilului financiar ce va fi utilizat pentru finalizarea activităților dincererea de finanțare. Declarația va avea forma prezentată în 
                    <a class="d-inline" href="{{coreModuleUrl}}/anexa1.docx"><strong>Anexa nr.1 [AI1] - Declarație privind capacitatea financiară a solicitantului.</strong></a>
                </p>
                <p><strong>&nbsp;</strong></p>
                <p><strong>2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Cererea de finanțare</strong></p>
                <p>
                    Cererile de finanțare trebuie sa cuprindă informații cu privire la planificarea formării profesionale a personalului care va utiliza echipamentele/sistemele informatice ce urmează a fi achiziționate. 
                    Modelul cererii de finanțare se completează conform instrucțiunilor din 
                    <a class="d-inline" href="{{coreModuleUrl}}/anexa2.doc"><strong>Anexa nr. 2 [AI2] – Cererea de Finanțare.</strong></a>
                </p>
                <p><strong>&nbsp;</strong></p><p><strong>3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Formarea profesională</strong></p>
                <p>
                    Cererile de finanțare trebuie sa cuprindă informații cu privire la planificarea formăriiprofesionale a personalului care va utiliza echipamentele/sistemele informaticece urmează a fi achiziționate. 
                    Formarea profesională se va asigura pecheltuiala proprie a autorităților executive la nivelul unităților administrativ – teritoriale și este condiție eliminatorie pentru acordareafinanțării. 
                    <strong>Dovada îndeplinirii acestei condiții se va realiza prindepunerea unei declarații pe proprie răspundere a reprezentantului legal</strong>
                    , conform <a class="d-inline" href="{{coreModuleUrl}}/anexa3.docx"><strong>Anexei nr. 3 [AI3] - Declarație cu privire la formarea profesională a personaluluicare va utiliza echipamentele/sistemele informatice ce urmează a fiachiziționate</strong></a>
                </p>
                <p><strong>&nbsp;</strong></p>
                <p><strong>4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Existența unei pagini de internet</strong></p>
                <p>
                    Cererile de finanțare vor conține informații privind existența sau nu a unei pagini deinternet securizată, proprie, funcțională. În situația în care aceasta nu există, finanțarea nu va fi acordată decât în situația în care o parte din suma solicitată va fi alocată construirii unei pagini de internet. 
                    <strong>Dovada îndeplinirii acestei condiții se va realiza prin depunerea unei declarații pe proprie răspundere a reprezentantului legal</strong>, conform 
                    <a class="d-inline" href="{{coreModuleUrl}}/anexa4.docx"><strong>Anexei nr. 4 [AI4] - Declarație cu privire la existența sau nu a unei pagini de internet securizată, proprie, funcțională.</strong></a>
                </p>
                <p><strong>&nbsp;</strong></p>
                <p><strong>5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Asigurarea sustenabilității proiectului</strong></p>
                <p>
                    Cererile de finanțare trebuie sa cuprindă informații cu privire la asigurarea surselor de finanțare, din bugetul propriu, pentru o perioadă de minim 3 ani, în vederea asigurării sustenabilității echipamentelor/sistemelor informatice ce urmează a fi achiziționate.
                    <strong> Dovada îndeplinirii acestei condiții </strong>va respecta modelul prevăzut în 
                    <a class="d-inline" href="{{coreModuleUrl}}/anexa5.docx"><strong>Anexa nr. 5 [AI5] - Declarație cu privire la asigurarea sustenabilității echipamentelor/sistemelor informatice ce urmează a fi achiziționate.</strong></a>
                </p>
                <p><strong>&nbsp;</strong></p>
                <p><strong>6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Evitarea dublei finanțări </strong></p>
                <p>Beneficiarii depun<strong> o declarație pe proprie răspundere, </strong>prevăzută în <a class="d-inline" href="{{coreModuleUrl}}/anexa6.docx"><strong> Anexa nr. 6 [AI6] - Declarație privind îndeplinirea condiției prevăzute la art. 1 alin. (10) din OUG nr. 56/2024,</strong></a>
                    potrivit căreia activitățile/produsele ce fac obiectul cererii definanțare nu sunt eligibile la finanțare în programele derulate din fonduri externe nerambursabile în perioada de programare 2021-2027, prin Planul național de redresare și reziliență al României ori prin alte programe naționale sau comunitare, inclusiv din contracte de împrumut semnate cu instituții de credit sau instituții financiare interne sau internaționale<strong>.&nbsp;</strong>
                </p>
                <p><strong>&nbsp;</strong></p>
                <p><strong>7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Servicii eligibile </strong></p>
                <p>
                    Sunt considerate eligibile exclusiv cererile de finanțare în care toate echipamentele/sistemele informatice ce urmează a fi achiziționate, conform descrierii din cererile de finanțare depuse, 
                    sunt destinate furnizării deservicii publice.<strong> Dovada îndeplinirii acestei condiții </strong>se face conform modelului din 
                    <a class="d-inline" href="{{coreModuleUrl}}/anexa7.docx"><strong>Anexa nr. 7 [AI7] - Declarație privind destinația echipamentelor/sistemelor informatice ce urmează a fi achiziționate.</strong></a>
                </p>
                <p><strong>&nbsp;</strong></p>
                <p><strong>8.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Înrolarea în ghiseul.ro</strong></p>
                <p>
                    Cererile de finanțare vor conține informații privind <strong>înrolarea autorității executiveîn platforma ghiseul.ro. În situația în care aceasta nu există, cererea definanțare este eligibilă doar dacă procedura de înrolare este finalizată întermen de maxim 60 zile. 
                        Declarația îndeplinirii acestei condiții </strong>va respecta modelul prevăzut în 
                        <a class="d-inline"href="{{coreModuleUrl}}/anexa8.docx"><strong> Anexa nr. 8 [AI8] - Declarație privind înrolarea autorității executive în platforma ghiseul.ro</strong></a>
                </p>
                <p><strong>Dacă proiectul este câștigător, </strong></p>
                <p>
                    <strong>Lista completă a documentelor necesare conform criteriilor de eligibilitate prezentate mai sus. 
                        Apăsați aici </strong><a class="d-inline" href="#">[AI9]</a>&nbsp;<strong>pentru a descărca toate documentele sau selectați documentele necesare de mai jos:<br>‍</strong>
                </p>
                <p><a class="d-inline" href="{{coreModuleUrl}}/anexa1.docx"><strong>Anexa 1 – Declarație privind capacitatea financiară a solicitantului</strong></a></p>
                <p><a class="d-inline" href="{{coreModuleUrl}}/anexa2.doc"><strong>Anexa 2 – Cererea de Finanțare</strong></a></p>
                <p><a class="d-inline" href="{{coreModuleUrl}}/anexa3.docx"><strong>Anexa 3 – Declarație cu privire laformarea profesională a personalului care va utiliza echipamentele/sistemeleinformatice ce urmează a fi achiziționate</strong></a></p>
                <p><a class="d-inline" href="{{coreModuleUrl}}/anexa4.docx"><strong>Anexa 4 – Declarație cu privire laexistența sau nu a unei pagini de internet securizată, proprie, funcțională</strong></a></p>
                <p><a class="d-inline" href="{{coreModuleUrl}}/anexa5.docx"><strong>Anexa 5 – Declarație cu privire laasigurarea sustenabilității echipamentelor/sistemelor informatice ce urmează afi achiziționate</strong></a></p>
                <p><a class="d-inline" href="{{coreModuleUrl}}/anexa6.docx"><strong>Anexa 6 – Declarație privind</strong>&nbsp;<strong>îndeplinirea condiției prevăzute la art. 1 alin. (10) din OUG nr. 56/2024</strong></a></p>
                <p><a class="d-inline" href="{{coreModuleUrl}}/anexa7.docx"><strong>Anexa 7 – Declarație privinddestinația echipamentelor/sistemelor informatice ce urmează a fi achiziționate</strong></a></p>
                <p><a class="d-inline" href="{{coreModuleUrl}}/anexa8.docx"><strong>Anexa 8 – Declarație privindînrolarea autorității executive în platforma ghișeul.ro</strong></a></p>
                <p><a class="d-inline" href="{{coreModuleUrl}}/anexa9.docx"><strong>Anexa 9 – Contractul de Finanțare</strong></a></p>
                <p><strong>‍</strong></p>
                <p><strong>Orice UAT poate să obțină finanțare o singură dată prin prezentul Program.</strong></p>
                <!-- <p><strong>‍</strong></p> -->
            </div>
            <h2 class="heading-style-h4 text-color-gov mt-5">Negocierea bugetului și semnarea contractelor de finanțare</h2>
            <div class="w-richtext">
                <p>
                    <strong>Pentru cererile de finanțare selectate se încheie un </strong>
                    <a class="d-inline" href="{{coreModuleUrl}}/anexa9.docx"><strong>contract de finanțare [AI1] </strong></a>
                    <strong>&nbsp;</strong>între două părți: Ministerul Cercetării, Inovării și Digitalizării în calitate de autoritate contractantă și Beneficiar în calitate de contractor.
                </p>
                <!-- <p>&nbsp;</p> -->
                <p>
                    Bugetul ce face obiectul contractului nu poate depăși valoarea bugetului solicitat inițial prin Cererea de finanțare. 
                    Se va acorda un avans de 30% din valoarea finanțării, în funcție de tipul de beneficiar, diferența se va deconta ulterior, după depunerea tuturor documentelor justificative.
                </p>
            </div>
            <h2 class="heading-style-h4 text-color-gov mt-5">Definiții utile</h2>
            <div class="w-richtext">
                <p>
                    <strong>Sisteme informatice - </strong>prin sistem informatic se înțelege orice dispozitiv sau ansamblu de dispozitive interconectate sau aflate în relație funcțională, dintre care unul sau mai multe asigură prelucrarea automată a datelor, cu ajutorul unui program informatic, destinat furnizării de servicii publice.
                </p>
                <p>&nbsp;</p>
                <p>
                    <strong>Echipamente hardware achiziționate și instalate </strong>- ansamblul dispozitivelor cu ajutorul cărora datele se pot culege, verifica, prelucra, transforma, transmite, afișa și stoca datele care fac obiectul sistemului achiziționat, destinate furnizării de servicii publice.
                </p>
                <p>&nbsp;</p>
                <p>
                    <strong>Aplicații -</strong> Soluții software/licențe implementate/achiziționate de tip COTS - Produse disponibile care nu necesită dezvoltare ci doar instalare și/sau personalizare fiind adaptate la nevoile organizației care le achiziționează în vederea prestării de servicii publice.
                </p>
                <p>&nbsp;</p>
                <p>
                    <strong>Servicii conexe -</strong>servicii suport pentru funcționarea aplicațiilor achiziționate în vederea furnizării de servicii publice.
                </p>
                <p><strong>&nbsp;</strong></p>
                <p>
                    <strong>Servicii de integrare cu aplicații/sisteme ale autorităților și instituțiilor publice centrale și europene: </strong>servicii de dezvoltare software pentru interconectarea aplicațiilor, existente la nivelul autorității executive, cu alte sisteme ale autorităților și instituțiilor publice centrale și europene în vederea furnizării de servicii publice.
                </p>
                <p>&nbsp;</p>
                <p>
                    <strong>Digitalizare - </strong>adoptarea continuă și implementarea tehnologiilor informației și comunicațiilor digitale pentru a transforma modul de interacțiune dintre utilizatorii și beneficiarii serviciilor publice, precum și asigurarea cadrului de interoperabilitate a sistemelor informatice.
                </p>
                <p>&nbsp;</p>
                <p>
                    <strong>Transformare digitală </strong>se referă la transformarea, folosind tehnologiile digitale și de comunicații, a:
                </p>
                <p> 
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;a)&nbsp;modului de interacțiune dintre administrația publică și cetățeni;
                </p>
                <p> 
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;b)&nbsp;relațiilor funcționale dintre instituțiile statului;
                </p>
                <p> 
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;c)&nbsp;modului în care datele generate și colectate de instituțiile statului sunt utilizate în luarea deciziilor și crearea politicilor publice;
                </p>
                <p> 
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;d)&nbsp;modului în care inovarea, în cazul serviciilor publice, consideră identificarea problemei, testarea și actualizarea ca făcând parte integrantă din proces;
                </p>
                <p> 
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;e)&nbsp;evaluării și anticipării continue a nevoilor publicului și adaptarea serviciilor la acestea.
                </p>
                <p>
                    Beneficiar: autoritățile executive la nivelul unităților administrativ – teritoriale, astfel cum sunt definite la art. 5 lit. n) din Ordonanța de urgență a Guvernului nr. 57/2019 privind Codul administrativ, cu modificările și completările ulterioare.
                </p>
            </div>
        </div>
        
    </div>    
</div>