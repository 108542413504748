<div class="contact-container">
    <div class="contact-content d-flex flex-column align-items-start align-self-stretch">
        <div class="contact-content-container d-flex flex-column align-self-stretch">
            <div *ngIf="!supportResponse">
                <h2 class="text-center mb-4">Suport tehnic</h2>
                <form [formGroup]="formContact">
                    <div class="row">
                        <mat-form-field class="col-md">
                            <mat-label>Subiect</mat-label>
                            <input matInput type="text" formControlName="subiect" />
                            <mat-error *ngIf="!formContact.controls['subiect'].valid && formContact.controls['subiect'].touched">
                                Câmp obligatoriu
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="row">
                        <mat-form-field class="col-md-4">
                            <mat-label>Nume</mat-label>
                            <input matInput type="text" formControlName="nume" />
                            <mat-error *ngIf="!formContact.controls['nume'].valid && formContact.controls['nume'].touched">
                                Câmp obligatoriu
                            </mat-error>
                        </mat-form-field>
    
                        <mat-form-field class="col-md-4">
                            <mat-label>Prenume</mat-label>
                            <input matInput type="text" formControlName="prenume" />
                            <mat-error *ngIf="!formContact.controls['prenume'].valid && formContact.controls['prenume'].touched">
                                Câmp obligatoriu
                            </mat-error>
                        </mat-form-field>
    
                        <mat-form-field class="col-md-4">
                            <mat-label>Email</mat-label>
                            <input matInput type="email" formControlName="email" />
                            <mat-error
                                *ngIf="formContact.controls['email'] && (formContact.controls['email'].dirty || formContact.controls['email'].touched) && formContact.controls['email'].hasError('required')">
                                Câmp obligatoriu
                            </mat-error>
                            <mat-error
                                *ngIf="formContact.controls['email'] && (formContact.controls['email'].dirty || formContact.controls['email'].touched) && (formContact.controls['email'].hasError('email') || formContact.controls['email'].hasError('pattern'))">
                                Email invalid
                            </mat-error>
                        </mat-form-field>
                    </div>
                    
                    <div class="row">
                        <mat-form-field class="col-md-4">
                            <mat-label>Telefon</mat-label>
                            <button disabled="true" type="button" class="country-selector" mat-button matPrefix [matMenuTriggerFor]="menu">
                                <mat-icon>expand_more</mat-icon>
                                {{ currentOption.flag }} {{ currentOption.dial_code }}
                            </button>
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="changeCountry(optionRom)">
                                    <span class="flag-wrapper">🇷🇴</span>
                                    <span class="label-wrapper">
                                        {{ optionRom.name }} {{ optionRom.dial_code }}
                                    </span>
                                </button>
                                <mat-divider class="divider"></mat-divider>
                                <button mat-menu-item *ngFor="let option of options" (click)="changeCountry(option)">
                                    <span class="flag-wrapper">{{ option.flag }}</span>
                                    <span class="label-wrapper">
                                        {{ option.name }} {{ option.dial_code }}
                                    </span>
                                </button>
                            </mat-menu>
                            <input type="text" matInput formControlName="telefon" />
                            <mat-error *ngIf="telefon && (telefon.dirty || telefon.touched) && telefon.hasError('required')">
                                Telefonul este obligatoriu.
                            </mat-error>
                            <mat-error *ngIf="telefon && (telefon.dirty || telefon.touched) && telefon.hasError('pattern')">
                                Formatul telefonului este invalid. Trebuie să fie un număr de telefon mobil românesc valid.
                            </mat-error>
                        </mat-form-field>
                    </div>
                 
                    <div class="row">
                        <mat-form-field class="col-md">
                            <mat-label>Mesaj</mat-label>
                            <textarea matInput type="text" formControlName="mesaj"></textarea>
                            <mat-error *ngIf="!formContact.controls['mesaj'].valid && formContact.controls['mesaj'].touched">
                                Câmp obligatoriu
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="request-upload d-flex flex-column">
                        <div class="informations-container">
                            <div *ngFor="let doc of documents">
                                <div class="documents p-0">
                                    <div class="upload-container">
                                        <div class="uploaded-files justify-content-center">
                                            <label class="d-flex">
                                                <span *ngIf="doc.isRequired" class="red-text">*&nbsp;</span>
                                                {{ doc.name }}
                                                <a *ngIf="doc.url" [href]="doc.url" target="_blank">&nbsp;(Descarcă cererea)</a>                                    
                                            </label>
                                            <ng-container>
                                                <div class="uploaded-files-container">
                                                    <div *ngFor="let fg of commonService.getFormArray(formContact, doc.key).controls"
                                                        class="success-upload docs-list-uploaded-container">
                                                        <p>{{ fg.get("original_name")?.value }}</p>
                                                        <button type="button" class="delete"
                                                            (click)="deleteFile(doc.key, fg.get('filename')?.value)">
                                                            <img class="delete-icon" src="assets/icons/delete.svg" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </ng-container>
                                            <mat-spinner diameter="32"
                                                *ngIf="commonService.getUploading(documents, doc.key)"></mat-spinner>
                                        </div>
                                        <div>
                                            <label for="{{ doc.key }}">
                                                <button type="button" (click)="fileInput.click()">
                                                    <span>
                                                        <img src="assets/icons/upload.svg" alt="" />
                                                    </span>
                                                    <span class="show_hide_text">Încărcați</span>
                                                </button>
                                                <input #fileInput type="file" id="{{ doc.key }}"
                                                    (change)="onFileSelected($event, doc.key)" class="upload-input" multiple />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="contact-content-container__info d-flex flex-column flex-md-row justify-content-between align-items-center">
                        <div class="contact-content-container__info-text">
                            <p class="info text-red">
                                <mat-icon class="info-icon text-red">info</mat-icon>Câmpurile marcate cu
                                <span class="text-red">&nbsp;*&nbsp;</span>sunt obligatorii
                            </p>
                            <p class="info text-red">
                                <mat-icon class="info-icon red-text">info</mat-icon>Fișierele atașate trebuie să fie de tip imagine sau PDF, de maxim 10MB fișierul
                            </p>
                            <div class="d-flex align-items-center">
                                <mat-checkbox formControlName="prelucrareDate" color="primary"></mat-checkbox>
                                <strong class="m-0">Sunt de acord cu prelucrarea datelor în conformitate cu politica de confidențialitate și securitate a datelor cu caracter personal.</strong>
                            </div>
                        </div>
                        <button class="btn btn-primary send-button mt-3 mt-md-0" (click)="submitForm()" type="submit"  [disabled]="!formContact.valid">
                            Trimite
                        </button>
                    </div>
                </form>
            </div>
            <ng-container *ngIf="supportResponse">
                <div class="row gap-4 text-center">
                    <div class="success d-flex justify-content-center align-items-center gap-3">
                        <img src="assets/icons/success-check.svg" alt="Succes" />
                        <span class="success-text">Succes!</span>
                    </div>
                    <span class="message-text">Depunerea a fost finalizată!</span>
                    <span class="verify-text">Verificați rezultatele aici:</span>
                    <div>
                        <button type="button" class="result-btn" (click)="navigateToCentralizator()">
                            REZULTATE
                        </button>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
