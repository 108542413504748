import { Component, Inject } from '@angular/core';
import { finalize } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NotificationService } from '../../services/notification.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
    selector: 'app-faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.scss']
})
export class FaqComponent {
    PageSection: any = null
    FAQList: { [key: string]: any[] } = {};
    isLoading = false;
    titles: string[] = ['Întrebări frecvente']
    slugs: string[] = ['DEPUNERE']; // TODO add relevant slugs here

    constructor(
        private commonService: CommonService,
        private notificationService: NotificationService
    ) { }

    ngOnInit(): void {
        this.loadFAQs();
    }

    getFAQS(slug: string) {
        this.isLoading = true

        this.commonService.getFAQ(slug)
            .pipe(
                finalize(() => this.isLoading = false)
            )
            .subscribe({
                next: async (res: any) => {
                    let response = (typeof res.status_code !== 'undefined' ? res : res.error)
                    if (typeof response.status_code !== 'undefined') {
                        if (response.status_code == 200 && typeof response.data !== 'undefined') {
                            this.FAQList[slug] = response.data
                            this.isLoading = false
                            return;
                        }
                    } else {
                        let errorMessage = environment.config.customNotifications.generalMessages.error;
                        await this.notificationService.warningSwal(environment.config.customNotifications.headers.error, errorMessage, environment.config.customNotifications.icons.error);
                    }
                },
                error: (res: any) => {
                    this.notificationService.handleHttpError(res.error);
                }
            });
    }

    loadFAQs() {
        for (const slug of this.slugs) {
            this.getFAQS(slug);
        }
    }
}
