import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { ICountryOption, countriesList } from '../../shared/countries-list';
import { NotificationService } from 'src/app/services/notification.service';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { finalize } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { ToastService } from 'src/app/services/toast.service';
import { UploadDocument } from 'src/app/models';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

const phoneNumberUtil = PhoneNumberUtil.getInstance();

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
    appName = environment.config.appName;
    supportResponse: any;
    documents: UploadDocument[] = [
        {
            key: 'files',
            uploading: false,
            name: 'Documente/Fotografii',
            isRequired: false
        }
    ];

    formContact: FormGroup = new FormGroup({
        nume: new FormControl(''),
        prenume: new FormControl(''),
        email: new FormControl(''),
        telefon: new FormControl(''),
        subiect: new FormControl(''),
        mesaj: new FormControl(''),
        prelucrareDate: new FormControl('')
    })

    isLoading = false;

    optionRom: ICountryOption = {
        name: 'Romania',
        flag: '🇷🇴',
        code: 'RO',
        dial_code: '+40',
    };

    currentOption = this.optionRom;
    options: ICountryOption[] = countriesList;

    changeCountry(option: ICountryOption) {
        this.currentOption = option;
    }

    env: any = environment

    // general error
    errorTitle: string = environment.config.customNotifications.headers.error
    errorIcon: string = environment.config.customNotifications.icons.error
    errorType: string = environment.config.customNotifications.icons.error
    // general success alert
    successTitle: string = environment.config.customNotifications.headers.successForm
    successIcon: string = environment.config.customNotifications.icons.success
    successType: string = environment.config.customNotifications.icons.success

    constructor(
        private fb: FormBuilder,
        private toastService: ToastService,
        private notificationService: NotificationService,
        public toastr: ToastrService,
        public commonService: CommonService,
        private router: Router,
    ) { }

    ngOnInit(): void {
        this.initContactForm();
    }

    initContactForm() {
        this.formContact = this.fb.group({
            nume: ['', [Validators.required]],
            prenume: ['', [Validators.required]],
            email: ['', [
                Validators.required,
                Validators.pattern(environment.config.emailRegex),
                Validators.maxLength(environment.config.validatorsAccrossApp.emailMaxLength),
                Validators.email
            ]],
            telefon: ['', Validators.compose([
                Validators.pattern(environment.config.phoneRegex),
                Validators.required])
            ],
            subiect: ['', [Validators.required]],
            mesaj: ['', [Validators.required]],
            prelucrareDate: [false, [Validators.requiredTrue]]
        })

        this.commonService.addUploadingControls(this.formContact, this.documents);
    }

    get telefon() {
        return this.formContact.get('telefon');
    }

    get files(): FormArray {
        return this.formContact.get('files') as FormArray;
    }

    submitForm() {
        this.formContact.markAllAsTouched();
        this.formContact.updateValueAndValidity();

        if (!this.formContact.valid) {
            this.notificationService
                .warningSwal(
                    environment.config.customNotifications.headers.error,
                    'Cererea dumneavoastră nu poate fi trimisă deoarece există erori. Verificați din nou câmpurile obligatorii și validitatea informațiilor.',
                    environment.config.customNotifications.icons.error
                )
                .then((result) => {
                    if (result.isConfirmed) {
                        setTimeout(() => {
                            console.log('scrolling to top ...');
                            window.scrollTo({ top: 0, behavior: 'auto' });
                        }, 1000);
                    }
                });

            return;
        }

        this.isLoading = true;
        let phone: any;

        const teletelefonInput = this.currentOption.dial_code + this.formContact.value.telefon;
        const telefon = phoneNumberUtil.parseAndKeepRawInput(teletelefonInput)
        let regionCodeLower = '';
        let nationalNumberString = '';
        if (telefon) {
            const regionCode = phoneNumberUtil.getRegionCodeForNumber(telefon);
            if (regionCode) {
                regionCodeLower = (regionCode as string).toLowerCase();
            }

            const nationalNumber = telefon.getNationalNumber();

            if (nationalNumber) {
                nationalNumberString = nationalNumber.toString();
            }
        }

        phone = {
            dialCode: '',
            internationalNumber: teletelefonInput,
            isoCode: regionCodeLower,
            nationalNumber: nationalNumberString
        }

        phone.dialCode = teletelefonInput.replace(phone.nationalNumber, '');
        phone.nationalNumber = (phone.isoCode == 'ro' ? '0' + phone.nationalNumber : phone.nationalNumber);

        const dataToSend = {
            ...this.formContact?.getRawValue(), telefon: phone.internationalNumber
        };

        return this.commonService.sendContact(dataToSend)
            .subscribe({
                next: (res: any) => {
                    let response = typeof res.status_code !== 'undefined' ? res : res.error;
                    if (typeof response.status_code !== 'undefined') {
                        if (response.status_code == 200 && typeof response.data !== 'undefined') {
                            this.isLoading = false;
                            this.supportResponse = response;
                        }
                    } else {
                        // add sentry
                        let errorMessage = environment.config.customNotifications.generalMessages.error;
                        this.notificationService.warningSwal(this.errorTitle, errorMessage, this.errorIcon);
                        this.isLoading = false;
                    }
                },
                error: (err: any) => {
                    this.isLoading = false;
                    this.notificationService.handleHttpError(err.error);
                },
            });
    }

    async onFileSelected(event: Event, key: string) {
        const selectedFiles = (event.target as HTMLInputElement).files;
        if (!selectedFiles) {
            return;
        }

        const uploadPromises = Array.from(selectedFiles).map(async (file) => {
            await this.commonService.upload(file, key, this.documents, this.formContact);
        });

        try {
            await Promise.all(uploadPromises);
        } catch (error) {
            console.error(error)
        }
    }

    deleteFile(key: string, fileName: string) {
        const fa = this.commonService.getFormArray(this.formContact, key);
        const indexToDelete = fa.controls.findIndex((control: any) => {
            const filenameControl = control.get('filename') as FormControl;
            return filenameControl.value === fileName;
        });
        if (indexToDelete >= 0) {
            fa.removeAt(indexToDelete);
        }
    }

    navigateToCentralizator() {
        this.router.navigate(['depunere/centralizator']);
    }
}
