import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function atLeastOneFieldValidator(field1: string, field2: string): ValidatorFn {
    return (formGroup: AbstractControl): ValidationErrors | null => {
        const control1 = formGroup.get(field1);
        const control2 = formGroup.get(field2);

        if (control1 && control2 && control1 instanceof AbstractControl && control2 instanceof AbstractControl) {
            const value1 = control1.value;
            const value2 = control2.value;

            if ((value1 && !value2) || (!value1 && value2) || (value1 && value2)) {
                control1.setErrors(null);
                control2.setErrors(null);
                return null;
            } else {
                const errors = { exclusive: true };
                control1.setErrors(errors);
                control2.setErrors(errors);
                return errors;
            }
        }

        return null;
    };
}